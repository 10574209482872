import { useEffect, useState, Fragment } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import './CampaignDetails.css';
import {
	DeleteCampaignFiles,
	GetCampaign,
	GetCampaignFiles,
	GetCampaignMessageDetails,
	GetCampaignMessagesByType,
	GetCampaignReports,
	GetReportHistoryListWithSprocForPortal,
	GetRequirementDetails,
	SubmitChangeRequest,
	UploadCampaignFiles
} from '../../../Services';
import Moment from 'react-moment';
import moment from 'moment';
import Spinner from '../../../components/spinner/Spinner';
import PageHeadline from '../../../components/pageHeadline/PageHeadline';
import ModalDialog from '../../../components/modalDialog/ModalDialog';
import ModalDialogContent from '../../../components/modalDialog/modalDialogContent/ModalDialogContent';
import ModalDialogButtons from '../../../components/modalDialog/modalDialogButtons/ModalDialogButtons';
import { toast } from 'react-toastify';
import TableFooter from '../../../components/table/footer/TableFooter';
import FileUpload from '../../../components/fileUpload/FileUpload';
import AskQuestionDialog from '../askQuestionDialog/AskQuestionDialog';
import CancelCampaignDialog from '../cancelCampaignDialog/CancelCampaignDialog';

const CampaignDetails = () => {
	const [ isLoading, setIsLoading ] = useState(false);
	const [ isSubmittingChangeRequest, setIsSubmittingChangeRequest ] = useState(false);
	const [ isProcessingImage, setIsProcessingImage ] = useState(false);
	const [ campaign, setCampaign ] = useState();
	const { campaignId } = useParams();
	const [ reports, setReports ] = useState();
	const [ allSentReports, setAllSentReports ] = useState();
	const [ requirementData, setRequirementData ] = useState({});
	const [ changeRequests, setChangeRequests ] = useState();
	const [ showChangeRequestDialog, setShowChangeRequestDialog ] = useState(false);
	const [ showImagePreviewDialog, setShowImagePreviewDialog ] = useState(false);
	const [ changeRequestSubject, setChangeRequestSubject ] = useState();
	const [ changeRequestText, setChangeRequestText ] = useState();
	const [ changeRequestImages, setChangeRequestImages ] = useState();
	const [ isLoadingChangeRequest, setIsLoadingChangeRequest ] = useState(false);
	const [ changeRequestId, setChangeRequestId ] = useState();
	const [ imagePreviewUrl, setImagePreviewUrl ] = useState();
	const [ isServiceAreaBusiness, setIsServiceAreaBusiness ] = useState(false);
	const [ showAskQuestionDialog, setShowAskQuestionDialog ] = useState(false);
	const [ showCancelCampaignDialog, setShowCancelCampaignDialog ] = useState(false);
	const [ payments, setPayments ] = useState();
	const navigate = useNavigate();

	useEffect(
		() => {
			setIsLoading(true);

			if (campaignId) {
				getCampaign();
			}
		},
		[ campaignId ]
	);

	useEffect(
		() => {
			if (campaign) {
				let tempCampaign = campaign;

				if (tempCampaign.nextReportDate) {
					tempCampaign.nextReportDate = moment(tempCampaign.nextReportDate).format('yyyy-MM-DD');
				}

				setCampaign(tempCampaign);

				//getCampaignReports();
				getReportHistory(true);
				getCampaignRequirementDetails();
				getChangeRequestImages();
				getCampaignRequests();

				setIsLoading(false);
			}
		},
		[ campaign ]
	);

	const getCampaign = async () => {
		const response = await GetCampaign(campaignId);

		if (response) {
			let tempCampaign = response;

			if (tempCampaign.keywords && tempCampaign.keywords.length) {
				tempCampaign.keywordsStr = '';

				tempCampaign.keywords.forEach((kwObj) => {
					tempCampaign.keywordsStr += kwObj.keyword + ', ';
				});

				tempCampaign.keywordsStr = tempCampaign.keywordsStr.substring(0, tempCampaign.keywordsStr.length - 2);
			}

			if (tempCampaign.services && tempCampaign.services.length) {
				tempCampaign.servicesStr = '';

				tempCampaign.services.forEach((serviceObj) => {
					tempCampaign.servicesStr += serviceObj.name + '\n';
				});

				tempCampaign.servicesStr = tempCampaign.servicesStr.substring(0, tempCampaign.servicesStr.length - 2);
			}

			setCampaign(tempCampaign);
		}
	};

	const getCampaignReports = async () => {
		const response = await GetCampaignReports(campaignId);

		if (response && response.reports && response.reports.length > 0) {
			setReports(
				response.reports.filter((x) => {
					if (x.status) {
						return x.status === 'Active';
					} else {
						return x;
					}
				})
			);
		}
	};

	const getReportHistory = async (fullReload = false) => {
		// if (fullReload) {
		// 	setIsLoading(true);
		// }

		let response = await GetReportHistoryListWithSprocForPortal(campaignId);

		if (response && response.length > 0) {
			let tempAllSentReports = response.filter(
				(x) => x.campaignId === campaignId && x.type === 'Report' && x.status === 'Sent'
			);

			tempAllSentReports = tempAllSentReports.sort(function(a, b) {
				return new Date(b.dateSent) - new Date(a.dateSent);
			});

			setAllSentReports(tempAllSentReports);
		} else {
			setAllSentReports([]);
		}

		//setIsLoading(false);
	};

	const getCampaignRequirementDetails = async () => {
		if (campaign && campaign.requirementId) {
			const response = await GetRequirementDetails(campaign.requirementId);

			if (response) {
				//setRequirementData(response);

				if (response.initialData) {
					let tempInitialData = JSON.parse(response.initialData);

					if (tempInitialData.Keywords) {
						tempInitialData.keywordsStr = '';

						tempInitialData.Keywords.split('\n').forEach((kwObj) => {
							tempInitialData.keywordsStr += kwObj + ', ';
						});

						tempInitialData.keywordsStr = tempInitialData.keywordsStr.substring(
							0,
							tempInitialData.keywordsStr.length - 2
						);
					}

					setRequirementData(tempInitialData);
				}
			}
		}
	};

	const getChangeRequestImages = async () => {
		const response = await GetCampaignFiles(campaignId);

		if (response) {
			let allChangeRequestImages = response.filter((x) => x.target.indexOf('ChangeRequest-') > -1);
			let tempChangeRequestImages = [];
			let today = moment().format('DD-MM-YYYY');

			for (let i = 0; i < allChangeRequestImages.length; i++) {
				//get only images with today date
				let imageDatePart = allChangeRequestImages[i].target.replace('ChangeRequest-', '');

				if (imageDatePart === today) {
					tempChangeRequestImages.push(allChangeRequestImages[i]);
				}
			}

			setChangeRequestImages(tempChangeRequestImages);
			setIsProcessingImage(false);
		}
	};

	const getCampaignRequests = async () => {
		const response = await GetCampaignMessagesByType(campaignId, 'ChangeRequest');

		if (response.success && response.data && response.data.length > 0) {
			let tempChangeRequests = response.data;

			tempChangeRequests.sort((a, b) => {
				const statusOrder = [ 'Pending', 'Active', 'Completed' ];

				const aStatusIndex = statusOrder.indexOf(a.status);
				const bStatusIndex = statusOrder.indexOf(b.status);

				if (aStatusIndex === bStatusIndex) return a.status - b.status;

				let compareByName = a.campaign.name.localeCompare(b.campaign.name);

				return aStatusIndex - bStatusIndex || compareByName;
			});

			setChangeRequests(tempChangeRequests);
		}
	};

	const getCampaignRequestDetails = async (id) => {
		setIsLoadingChangeRequest(true);

		const response = await GetCampaignMessageDetails(id);

		if (response.success && response.data) {
			setChangeRequestSubject(response.data.subject);
			setChangeRequestText(response.data.message);
		}

		setIsLoadingChangeRequest(false);
	};

	const toggleChangeRequestDialog = async (e, id = null) => {
		setChangeRequestImages();
		setShowChangeRequestDialog(!showChangeRequestDialog);

		if (id) {
			setChangeRequestId(id);
			getCampaignRequestDetails(id);
		} else {
			setChangeRequestId();
		}

		if (e) e.preventDefault();
	};

	const toggleImagePreviewDialog = async (e, url) => {
		setShowImagePreviewDialog(!showImagePreviewDialog);

		if (!showImagePreviewDialog) {
			setImagePreviewUrl(url);
		} else {
			setImagePreviewUrl();
		}

		if (e) e.preventDefault();
	};

	const handleChangeRequest = async () => {
		if (!changeRequestSubject || changeRequestSubject.trim() === '') {
			toast.error('Please enter request subject.');
			return;
		}

		if (!changeRequestText || changeRequestText.trim() === '') {
			toast.error('Please enter request text.');
			return;
		}

		setIsSubmittingChangeRequest(true);

		let data = { campaignId: campaignId, subject: changeRequestSubject, request: changeRequestText };

		if (changeRequestImages && changeRequestImages.length > 0) {
			data.images = [];

			changeRequestImages.forEach((image) => {
				data.images.push(image.url);
			});
		}

		let response = await SubmitChangeRequest(campaignId, data);

		if (response.success) {
			toggleChangeRequestDialog();
			getCampaignRequests();
			toast.success('Change request successfully submitted');
		}

		setIsSubmittingChangeRequest(false);
	};

	const handleFileUpload = async (files, requestId) => {
		//upload files on server
		if (files !== undefined) {
			setIsProcessingImage(true);

			var data = new FormData();

			for (var i = 0; i !== files.length; i++) {
				data.append('Files', files[i]);
			}

			data.append('Target', `ChangeRequest-${moment().format('DD-MM-YYYY')}`);

			const response = await UploadCampaignFiles(campaignId, data);

			if (response !== undefined && response.data && response.data.length > 0) {
				let imageUrl = response.data[0].fullCdnUrl;

				getChangeRequestImages();
			} else {
				setIsProcessingImage(false);
			}
		}
	};

	const handleDeleteImage = async (e, id) => {
		e.preventDefault();

		//delete image from database
		if (window.confirm('Are you sure that you want to delete this image?')) {
			setIsProcessingImage(true);

			let data = {
				campaignId : campaignId,
				ids        : [ id ]
			};

			const response = await DeleteCampaignFiles(campaignId, data);

			if (response.success) {
				getChangeRequestImages();
			} else {
				setIsProcessingImage(false);
			}
		}
	};

	const toggleAskQuestionDialog = async (e) => {
		setShowAskQuestionDialog(!showAskQuestionDialog);

		if (e) e.preventDefault();
	};

	const toggleCancelCampaignDialog = async (e) => {
		setShowCancelCampaignDialog(!showCancelCampaignDialog);

		if (e) e.preventDefault();
	};

	const handleOnCancelCampaignSubmit = async (e) => {
		setShowCancelCampaignDialog(!showCancelCampaignDialog);

		navigate('/campaigns');

		if (e) e.preventDefault();
	};

	return (
		<div className="app">
			<div className="container-fluid p-0">
				<div className="row">
					<div className="col-12">
						{isLoading || !campaign ? (
							<Spinner />
						) : (
							<PageHeadline headline={campaign.name} showSearchBox={false} />
						)}
					</div>
				</div>

				<div className="row mt-0 mt-sm-3 mb-2 px-sm-0 px-3">
					<div className="col-9">
						<button type="button" className="btn btn-primary small-btn" onClick={toggleChangeRequestDialog}>
							{/* <i className="fa-solid fa-pen-to-square me-0 d-block d-sm-none" /> */}
							<span>Change Request</span>
						</button>
						<button
							type="button"
							className="btn btn-primary small-btn ms-2"
							onClick={toggleAskQuestionDialog}
						>
							{/* <i className="fa-solid fa-question me-0 d-block d-sm-none" /> */}
							<span>Ask Question</span>
						</button>
					</div>
					<div className="col-3 d-flex justify-content-end">
						<button
							type="button"
							className="btn btn-link small-btn"
							onClick={toggleCancelCampaignDialog}
							title="Cancel Campaign"
						>
							{/* <i className="fa fa-search me-0 d-block d-sm-none" /> */}
							<span>Cancel</span>
						</button>
					</div>
				</div>

				<div className="main-content-container campaign-details-container">
					<ul className="nav nav-tabs" id="tabCampaigns" role="tablist">
						<li className="nav-item" role="presentation" key="reports">
							<button
								className={`nav-link active`}
								id="reports-tab"
								data-bs-toggle="tab"
								data-bs-target="#reports-tab-pane"
								type="button"
								role="tab"
								aria-controls="reports-tab-pane"
								aria-selected="true"
							>
								Reports
							</button>
						</li>
						<li className="nav-item" role="presentation" key="settings">
							<button
								className={`nav-link`}
								id="settings-tab"
								data-bs-toggle="tab"
								data-bs-target="#settings-tab-pane"
								type="button"
								role="tab"
								aria-controls="settings-tab-pane"
								aria-selected="true"
							>
								Settings
							</button>
						</li>
						<li className="nav-item" role="presentation" key="requirements">
							<button
								className="nav-link"
								id="requirements-tab"
								data-bs-toggle="tab"
								data-bs-target="#requirements-tab-pane"
								type="button"
								role="tab"
								aria-controls="requirements-tab-pane"
								aria-selected="false"
							>
								Requirements
							</button>
						</li>
						<li className="nav-item" role="presentation" key="changes">
							<button
								className="nav-link"
								id="changes-tab"
								data-bs-toggle="tab"
								data-bs-target="#changes-tab-pane"
								type="button"
								role="tab"
								aria-controls="changes-tab-pane"
								aria-selected="false"
							>
								Changes
							</button>
						</li>
					</ul>
					<div className="main-content-inner-container">
						<div className="tab-content border" id="tabCampaignDetailsContent">
							{/* reports */}
							<div
								className={`tab-pane fade show active`}
								id="reports-tab-pane"
								role="tabpanel"
								aria-labelledby="reports-tab"
								tabIndex="0"
							>
								<table className="items-list campaigns-list table table-responsive">
									<thead>
										<tr>
											<th scope="col">Created</th>
											<th scope="col" />
										</tr>
									</thead>
									{isLoading || !allSentReports ? (
										<tbody>
											<tr>
												<td className="p-3" colSpan={2}>
													<Spinner />
												</td>
											</tr>
										</tbody>
									) : (
										<tbody>
											{allSentReports && allSentReports.length > 0 ? (
												allSentReports.map((report, index) => {
													return (
														<Fragment key={index}>
															<tr>
																<td>
																	{report.dateCreated ? (
																		<Moment format="MM/DD/YYYY">
																			{report.dateCreated}
																		</Moment>
																	) : (
																		''
																	)}
																</td>
																<td className="task-action text-end">
																	<Link
																		className="btn btn-link py-0"
																		to={`/customer/report/${report.id}`}
																		target="_blank"
																		title="View Report"
																	>
																		View
																	</Link>
																</td>
															</tr>
														</Fragment>
													);
												})
											) : (
												<tr>
													<td className="p-3" colSpan={2}>
														<i>No records</i>
													</td>
												</tr>
											)}
										</tbody>
									)}

									{!isLoading && (
										<TableFooter
											list={reports}
											colspan={2}
											showRefreshButton={true}
											onListRefresh={() => getCampaignReports()}
										/>
									)}
								</table>
							</div>

							{/* settings */}
							<div
								className={`tab-pane fade`}
								id="settings-tab-pane"
								role="tabpanel"
								aria-labelledby="settings-tab"
								tabIndex="0"
							>
								{isLoading || !campaign ? (
									<Spinner />
								) : (
									<form className="row campaign-details-form text-start p-3 p-xl-4">
										<label htmlFor="name" className="form-label col-12">
											Name:
										</label>
										<div className="col-12 mb-3">
											<input
												type="text"
												className="form-control"
												name="name"
												value={campaign.name || ''}
												readOnly
											/>
										</div>

										<label htmlFor="industry" className="form-label col-12">
											Industry:
										</label>
										<div className="col-12 mb-3">
											<input
												type="text"
												className="form-control"
												name="industry"
												value={campaign.industry || ''}
												readOnly
											/>
										</div>
										<label htmlFor="keywords" className="form-label col-12">
											Keywords:
										</label>
										<div className="col-12 mb-3">
											<input
												type="text"
												className="form-control"
												name="keywords"
												value={campaign.keywordsStr || ''}
												readOnly
											/>
										</div>
										<label htmlFor="services" className="form-label col-12">
											Services:
										</label>
										<div className="col-12 mb-3">
											<textarea
												className="form-control"
												name="services"
												value={campaign.servicesStr || ''}
												rows={5}
												readOnly
											/>
										</div>
										<label htmlFor="website" className="form-label col-12">
											Website Url:
										</label>
										<div className="col-12 mb-3">
											<input
												type="text"
												className="form-control"
												name="website"
												value={campaign.website || ''}
												readOnly
											/>
										</div>
										<label htmlFor="googleBusinessUrl" className="form-label col-12">
											Google Business Url:
										</label>
										<div className="col-12 mb-3">
											<input
												type="text"
												className="form-control"
												name="googleBusinessUrl"
												value={campaign.googleBusinessUrl || ''}
												readOnly
											/>
										</div>
										<div className="input-group col-12 mx-0">
											<label htmlFor="streetAddress1" className="form-label">
												Address:
											</label>
											<input
												type="checkbox"
												className="form-check-input ms-4"
												name="isServiceAreaBusiness"
												value={isServiceAreaBusiness}
												readOnly
											/>
											<label htmlFor="isServiceAreaBusiness" className="form-label ms-2">
												Service Area Business
											</label>
										</div>
										{!isServiceAreaBusiness && (
											<div className="col-12 mb-3">
												<div className="input-group row mx-0">
													<div className="col-12 col-xl-5 px-0 pe-xl-2 mb-2 mb-xl-0">
														<input
															type="text"
															className="form-control"
															name="streetAddress1"
															value={campaign.streetAddress1 || ''}
															readOnly
														/>
													</div>
													<div className="col-12 col-xl-3 px-0 pe-xl-2 mb-2 mb-xl-0">
														<input
															type="text"
															className="form-control"
															name="city"
															value={campaign.city || ''}
															readOnly
														/>
													</div>
													<div className="col-12 col-xl-2 px-0 pe-xl-2 mb-2 mb-xl-0">
														<input
															type="text"
															className="form-control"
															name="state"
															value={campaign.state || ''}
															readOnly
														/>
													</div>
													<div className="col-12 col-xl-2 px-0 mb-2 mb-xl-0">
														<input
															type="text"
															className="form-control"
															name="postalCode"
															value={campaign.postalCode || ''}
															readOnly
														/>
													</div>
												</div>
											</div>
										)}
										<label htmlFor="phoneNumber" className="form-label col-12">
											Phone:
										</label>
										<div className="col-12 mb-3">
											<input
												type="phoneNumber"
												className="form-control"
												name="phoneNumber"
												value={campaign.phoneNumber || ''}
												readOnly
											/>
										</div>
										<label htmlFor="specialInstructions" className="form-label col-12">
											Special Instructions:
										</label>
										<div className="col-12 mb-3">
											<textarea
												className="form-control"
												name="specialInstructions"
												value={campaign.specialInstructions || ''}
												rows={5}
												readOnly
											/>
										</div>
									</form>
								)}
							</div>

							{/* requirements */}
							<div
								className={`tab-pane fade`}
								id="requirements-tab-pane"
								role="tabpanel"
								aria-labelledby="requirements-tab"
								tabIndex="0"
							>
								<form className="row requirements-details-form text-start p-3 p-xl-4">
									<label htmlFor="company" className="form-label col-12">
										Company:
									</label>
									<div className="col-12 mb-3">
										<input
											type="text"
											className="form-control"
											name="company"
											value={requirementData.Company || ''}
											readOnly
										/>
									</div>

									<label htmlFor="industry" className="form-label col-12">
										Industry:
									</label>
									<div className="col-12 mb-3">
										<input
											type="text"
											className="form-control"
											name="industry"
											value={requirementData.Industry || ''}
											readOnly
										/>
									</div>
									<label htmlFor="keywords" className="form-label col-12">
										Keywords:
									</label>
									<div className="col-12 mb-3">
										<input
											type="text"
											className="form-control"
											name="keywords"
											value={requirementData.keywordsStr || ''}
											readOnly
										/>
									</div>
									<label htmlFor="services" className="form-label col-12">
										Services:
									</label>
									<div className="col-12 mb-3">
										<textarea
											className="form-control"
											name="services"
											value={requirementData.Services || ''}
											rows={5}
											readOnly
										/>
									</div>
									<label htmlFor="website" className="form-label col-12">
										Website Url:
									</label>
									<div className="col-12 mb-3">
										<input
											type="text"
											className="form-control"
											name="website"
											value={requirementData.Website || ''}
											readOnly
										/>
									</div>
									<label htmlFor="googleBusinessUrl" className="form-label col-12">
										Google Business Url:
									</label>
									<div className="col-12 mb-3">
										<input
											type="text"
											className="form-control"
											name="googleBusinessUrl"
											value={requirementData.GoogleBusinessUrl || ''}
											readOnly
										/>
									</div>
									<div className="input-group col-12 mx-0">
										<label htmlFor="address" className="form-label">
											Address:
										</label>
										<input
											type="checkbox"
											className="form-check-input ms-4"
											name="isServiceAreaBusiness"
											value={isServiceAreaBusiness}
											readOnly
										/>
										<label htmlFor="isServiceAreaBusiness" className="form-label ms-2">
											Service Area Business
										</label>
									</div>
									{!isServiceAreaBusiness && (
										<div className="col-12 mb-3">
											<input
												type="text"
												className="form-control"
												name="address"
												value={requirementData.Address || ''}
												readOnly
											/>
										</div>
									)}
									<label htmlFor="phoneNumber" className="form-label col-12">
										Phone:
									</label>
									<div className="col-12 mb-3">
										<input
											type="phoneNumber"
											className="form-control"
											name="phoneNumber"
											value={requirementData.Phone || ''}
											readOnly
										/>
									</div>
									<label htmlFor="specialInstructions" className="form-label col-12">
										Special Instructions:
									</label>
									<div className="col-12 mb-3">
										<textarea
											className="form-control"
											name="specialInstructions"
											value={requirementData.SpecialInstructions || ''}
											rows={5}
											readOnly
										/>
									</div>
								</form>
							</div>

							{/* changes */}
							<div
								className={`tab-pane fade`}
								id="changes-tab-pane"
								role="tabpanel"
								aria-labelledby="changes-tab"
								tabIndex="0"
							>
								<table className="items-list campaigns-list table table-responsive">
									<thead>
										<tr>
											<th className="date" scope="col">
												Date Created
											</th>
											<th scope="col">Subject</th>
											<th className="status" scope="col">
												Status
											</th>
											<th className="task-action" scope="col" />
										</tr>
									</thead>
									{isLoading ? (
										<tbody>
											<tr>
												<td className="p-3" colSpan={4}>
													<Spinner />
												</td>
											</tr>
										</tbody>
									) : (
										<tbody>
											{changeRequests && changeRequests.length > 0 ? (
												changeRequests.map((request, index) => {
													return (
														<Fragment key={index}>
															<tr>
																<td className="date">
																	{request.dateCreated ? (
																		<Moment format="MM/DD/YYYY">
																			{request.dateCreated}
																		</Moment>
																	) : (
																		''
																	)}
																</td>
																<td>{request.subject}</td>
																<td className="status">{request.status}</td>
																<td className="task-action text-end">
																	<button
																		className="btn btn-link py-0"
																		onClick={(e) =>
																			toggleChangeRequestDialog(e, request.id)}
																		title="View Change Request"
																	>
																		View
																	</button>
																</td>
															</tr>
														</Fragment>
													);
												})
											) : (
												<tr>
													<td className="p-3" colSpan={4}>
														<i>No change requests</i>
													</td>
												</tr>
											)}
										</tbody>
									)}

									{!isLoading && (
										<TableFooter
											list={changeRequests}
											colspan={4}
											showRefreshButton={true}
											onListRefresh={() => getCampaignRequests()}
										/>
									)}
								</table>
							</div>
						</div>
					</div>
				</div>

				{showChangeRequestDialog && (
					<ModalDialog className="text-start" position="right-sidebar">
						<ModalDialogContent align="start">
							<h4 className="mb-4">
								<span>Change Request</span>
							</h4>
							<form className="change-request-form">
								<div className="row mb-3">
									<label htmlFor="changeRequestSubject" className="form-label col-12">
										Subject:
									</label>
									<div className="col-12 mb-3">
										<input
											type="text"
											className="form-control"
											name="changeRequestSubject"
											value={changeRequestSubject || ''}
											onChange={(e) => setChangeRequestSubject(e.target.value)}
											required
											disabled={isSubmittingChangeRequest || changeRequestId}
										/>
									</div>
									<label htmlFor="changeRequestText" className="form-label col-12">
										What are the change request details?
									</label>
									<div className="col-12 mb-3">
										<textarea
											className="form-control"
											name="changeRequestText"
											value={changeRequestText || ''}
											onChange={(e) => setChangeRequestText(e.target.value)}
											rows={5}
											required
											disabled={isSubmittingChangeRequest || changeRequestId}
										/>
									</div>
									<div className="row">
										<label htmlFor="name" className="form-label col-12 mb-3">
											Images:
										</label>

										{isProcessingImage && <Spinner />}

										{!isProcessingImage &&
										changeRequestImages &&
										changeRequestImages.length > 0 && (
											<div className="col-12 mb-2">
												<div className="uploaded-images">
													{changeRequestImages.map((image, index) => (
														<div className="image-item mb-1" key={image.id}>
															<span className="image-name me-5">{image.name}</span>
															<span className="image-preview ">
																<a
																	className="btn btn-link app-link-button me-2"
																	onClick={(e) =>
																		toggleImagePreviewDialog(e, image.url)}
																	title="Image Preview"
																>
																	<i className="fa-solid fa-eye me-0" />
																</a>
															</span>
															<span className="image-delete">
																<a
																	className="btn btn-link app-link-button me-2"
																	onClick={(e) => handleDeleteImage(e, image.id)}
																	title="Delete"
																>
																	<i className="fa-solid fa-xmark me-0 text-danger" />
																</a>
															</span>
														</div>
													))}
												</div>
											</div>
										)}

										<div className="col-12">
											{!changeRequestId && (
												<FileUpload
													onFileChange={handleFileUpload}
													imageType="ChangeRequest"
													buttonText="Upload Images"
													isDisabled={isSubmittingChangeRequest || changeRequestId}
												/>
											)}
										</div>
									</div>
								</div>
							</form>
						</ModalDialogContent>
						<ModalDialogButtons>
							<div className="row">
								<div className="text-start col-12">
									{!changeRequestId && (
										<button className="btn btn-primary me-2" onClick={handleChangeRequest}>
											{isSubmittingChangeRequest ? (
												<Fragment>
													<span className="spinner-border m-0 me-2" />
													<span>Submitting...</span>
												</Fragment>
											) : (
												<span>Submit</span>
											)}
										</button>
									)}
									<button
										className="btn btn-outline-primary"
										onClick={(e) => toggleChangeRequestDialog(e)}
									>
										<span>Cancel</span>
									</button>
								</div>
							</div>
						</ModalDialogButtons>
					</ModalDialog>
				)}

				{showImagePreviewDialog && (
					<ModalDialog className="text-start" position="full-screen">
						<ModalDialogContent>
							<div className="container-fluid d-flex h-100 p-0 align-items-center justify-content-center">
								{imagePreviewUrl && <img className="mh-100 border" src={imagePreviewUrl} />}
							</div>
						</ModalDialogContent>
						<ModalDialogButtons>
							<div className="row">
								<div className="text-start col-12">
									<button
										className="btn btn-outline-primary ms-2"
										onClick={(e) => toggleImagePreviewDialog(e)}
									>
										<span>Close</span>
									</button>
								</div>
							</div>
						</ModalDialogButtons>
					</ModalDialog>
				)}

				{showAskQuestionDialog && (
					<AskQuestionDialog
						campaignId={campaignId}
						onSubmit={toggleAskQuestionDialog}
						onClose={toggleAskQuestionDialog}
					/>
				)}

				{showCancelCampaignDialog && (
					<CancelCampaignDialog
						campaignId={campaignId}
						onSubmit={handleOnCancelCampaignSubmit}
						onClose={toggleCancelCampaignDialog}
					/>
				)}
			</div>
		</div>
	);
};

export default CampaignDetails;
