import { Fragment, useEffect, useState } from 'react';
import Spinner from '../../../components/spinner/Spinner';
import './OffersList.css';
import PageHeadline from '../../../components/pageHeadline/PageHeadline';
import TableFooter from '../../../components/table/footer/TableFooter';
import { useStateValue } from '../../../StateProvider';
import { DeleteOffer, GetUserOffers } from '../../../Services';
import { Link } from 'react-router-dom';
import { setOfferType } from '../../../utils/StorageUtil';
import OfferDetailsDialog from '../offerDetailsDialog/OfferDetailsDialog';
import { toast } from 'react-toastify';

const OffersList = () => {
	const [ { user }, dispatch ] = useStateValue();
	const [ isLoading, setIsLoading ] = useState(false);
	const [ offers, setOffers ] = useState([]);
	const [ showDialog, setShowDialog ] = useState(false);
	const [ editedOfferId, setEditedOfferId ] = useState(null);
	const [ duplicateOfferId, setDuplicateOfferId ] = useState(null);

	useEffect(() => {
		document.title = 'Cart';

		getOffers(true);
	}, []);

	useEffect(() => {
		document.addEventListener('visibilitychange', handleTabFocus);

		// Specify how to clean up after this effect:
		return () => {
			document.removeEventListener('visibilitychange', handleTabFocus);
		};
	}, []);

	// User has switched back to the tab
	const handleTabFocus = () => {
		if (document.visibilityState === 'visible') {
			//console.log('Tab is in focus');
			getOffers();
		}
	};

	const getOffers = async (fullReload = false) => {
		if (fullReload) {
			setIsLoading(true);
		}

		let response = await GetUserOffers();

		if (response && response.length > 0) {
			let tempActiveOffers = response.filter((x) => x.status === 'Active');

			tempActiveOffers = tempActiveOffers.sort((a, b) => a.name.localeCompare(b.name));

			setOffers(tempActiveOffers);
		}

		setIsLoading(false);
	};

	const handleProcessOrder = (offerId) => {
		setOfferType('user');
		window.open(`/orders/${offerId}`, '_blank');
	};

	const handleToggleDialog = async (e, id) => {
		if (e) e.preventDefault();

		setShowDialog(!showDialog);

		if (!showDialog) {
			if (id !== undefined) {
				//edit
				setEditedOfferId(id);
			} else {
				//new
				setEditedOfferId(null);
			}
		} else {
			setEditedOfferId(null);
			setDuplicateOfferId(null);
		}
	};

	const handleOfferSubmit = async () => {
		// if (onSubmit) {
		// 	onSubmit();
		// }

		handleToggleDialog();
		getOffers(true);
	};

	const handleDuplicateOffer = (id, showDialog = false) => {
		setDuplicateOfferId(id);
		setEditedOfferId(null);
		setShowDialog(showDialog);
	};

	const handleDeleteOffer = async (e, id) => {
		e.preventDefault();

		if (window.confirm('Are you sure that you want to delete selected offer?')) {
			let response = await DeleteOffer(id);

			if (response) {
				toast.success('Offer successfully deleted');

				getOffers();
			}
		}
	};

	return (
		<Fragment>
			<div className="row">
				<div className="col-12">
					<PageHeadline
						//headline={user.isSalesUser ? 'Offers' : 'Cart'}
						headline=""
						leftSideHtml={
							<Fragment>
								{user.isSalesUser && (
									<button
										type="button"
										className="btn btn-primary"
										onClick={(e) => handleToggleDialog(e, null)}
									>
										<span>New Offer</span>
									</button>
								)}
							</Fragment>
						}
					/>
				</div>
			</div>

			<Fragment>
				<div className="main-content-container offers-list-container">
					<div className="main-content-inner-container">
						<table className="items-list offers-list table table-responsive">
							<thead>
								<tr>
									<th scope="col">Product</th>
									<th className="amount" scope="col">
										Amount
									</th>
									<th className="task-action" scope="col" />
								</tr>
							</thead>
							{isLoading ? (
								<tbody>
									<tr>
										<td className="p-3" colSpan={5}>
											<Spinner />
										</td>
									</tr>
								</tbody>
							) : (
								<tbody>
									{offers && offers.length > 0 ? (
										offers.map((offer, index) => {
											return (
												<Fragment key={index}>
													<tr>
														<td className="campaign-name text-truncate" title={offer.name}>
															{offer.name}
														</td>
														<td className="amount">${offer.price}</td>
														<td className="task-action text-end">
															{/* <Link
																className="btn btn-link py-0"
																to={`/orders/${offer.id}`}
																title="View Order"
																target="_blank"
															>
																Order
															</Link> */}
															<button
																type="button"
																className="btn btn-link py-0"
																title={user.isSalesUser ? 'View' : 'Order'}
																onClick={() => handleProcessOrder(offer.id)}
															>
																{/* {user.isSalesUser ? 'View' : 'Order'} */}
																<i className="fa-solid fa-up-right-from-square" />
															</button>
															{user.isSalesUser && (
																<Fragment>
																	<button
																		type="button"
																		className="btn btn-link py-0"
																		title="Edit Offer"
																		onClick={(e) => handleToggleDialog(e, offer.id)}
																	>
																		{/* Edit */}
																		<i className="fa-solid fa-pen" />
																	</button>
																	<button
																		type="button"
																		className="btn btn-link py-0"
																		title="Duplicate Offer"
																		onClick={(e) =>
																			handleDuplicateOffer(offer.id, true)}
																	>
																		{/* Duplicate */}
																		<i className="fa-regular fa-copy" />
																	</button>
																	<button
																		type="button"
																		className="btn btn-link py-0 text-danger"
																		title="Delete Offer"
																		onClick={(e) => handleDeleteOffer(e, offer.id)}
																	>
																		{/* Delete */}
																		<i className="fa-solid fa-trash-can" />
																	</button>
																</Fragment>
															)}
														</td>
													</tr>
												</Fragment>
											);
										})
									) : (
										<tr>
											<td className="p-3" colSpan={5}>
												<i>No offers</i>
											</td>
										</tr>
									)}
								</tbody>
							)}

							{!isLoading && (
								<TableFooter
									list={offers}
									colspan={5}
									showRefreshButton={true}
									onListRefresh={() => getOffers(true)}
								/>
							)}
						</table>
					</div>
				</div>

				{showDialog && (
					<OfferDetailsDialog
						offerId={editedOfferId}
						onSubmit={handleOfferSubmit}
						onClose={handleToggleDialog}
						onDuplicate={handleDuplicateOffer}
						duplicateSourceId={duplicateOfferId}
						isSystem={false}
					/>
				)}
			</Fragment>
		</Fragment>
	);
};

export default OffersList;
