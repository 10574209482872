import { Fragment, useEffect, useState } from 'react';
import Spinner from '../../../components/spinner/Spinner';
import './OfferOrderPage.css';
import { GetOffer, ProcessOrder } from '../../../Services';
import Logo from '../../../components/logo/Logo';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { formatCurrency } from '../../../utils/Common';
import moment from 'moment';
import CountDownTimer from '../../../components/countDown/CountDownTimer';
import { toast } from 'react-toastify';
import { useStateValue } from '../../../StateProvider';
import { deleteOfferType, getOfferType } from '../../../utils/StorageUtil';

const OfferOrderPage = () => {
	const [ isLoading, setIsLoading ] = useState(false);
	const [ isSubmitting, setIsSubmitting ] = useState(false);
	const [ offerData, setOfferData ] = useState({});
	const [ cardNumbers, setCardNumbers ] = useState();
	const [ cardExpMonth, setCardExpMonth ] = useState();
	const [ cardExpYear, setCardExpYear ] = useState();
	const [ cardCVC, setCardCVC ] = useState();
	const [ cardBillingZip, setCardBillingZip ] = useState();
	const [ userFirstName, setUserFirstName ] = useState();
	const [ userLastName, setUserLastName ] = useState();
	const [ userEmail, setUserEmail ] = useState();
	const [ userPhone, setUserPhone ] = useState();
	const [ subscriptionStartDate, setSubscriptionStartDate ] = useState();
	const [ dmdExpirationDate, setDmdExpirationDate ] = useState();
	const [ offerDMDExpired, setOfferDMDExpired ] = useState(false);
	const [ offerFullPrice, setOfferFullPrice ] = useState();
	const [ offerPaymentPlan, setOfferPaymentPlan ] = useState();
	const [ offerIsActive, setOfferIsActive ] = useState(false);
	const { id } = useParams();
	const [ { user }, dispatch ] = useStateValue();
	const expMonths = [ '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12' ];
	const expYears = [ 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034 ];
	const [ searchParams ] = useSearchParams();
	const navigate = useNavigate();

	useEffect(() => {
		document.title = 'Map Boost Order Setup';

		getOffer();
	}, []);

	// check for query string param values
	useEffect(
		() => {
			if (searchParams && searchParams.keys()) {
				let tempOfferData = { ...offerData };

				tempOfferData.utm = {};

				if (searchParams.has('utm_source')) {
					tempOfferData.utm.UTMSource = searchParams.get('utm_source');
				}

				if (searchParams.has('utm_medium')) {
					tempOfferData.utm.UTMMedium = searchParams.get('utm_medium');
				}

				if (searchParams.has('utm_campaign')) {
					tempOfferData.utm.UTMCampaign = searchParams.get('utm_campaign');
				}

				if (searchParams.has('utm_term')) {
					tempOfferData.utm.UTMTerm = searchParams.get('utm_term');
				}

				if (searchParams.has('utm_content')) {
					tempOfferData.utm.UTMContent = searchParams.get('utm_content');
				}

				if (searchParams.has('gclid')) {
					tempOfferData.gclid = searchParams.get('gclid');
				}

				if (searchParams.has('fbclid')) {
					tempOfferData.fbclid = searchParams.get('fbclid');
				}

				if (searchParams.has('ttclid')) {
					tempOfferData.ttclid = searchParams.get('ttclid');
				}

				setOfferData(tempOfferData);
			}
		},
		[ searchParams ]
	);

	const getOffer = async () => {
		setIsLoading(true);

		let response = await GetOffer(id);

		if (response) {
			setOfferData(response);

			setOfferIsActive(response.status === 'Active');

			if (response.isSubscription && response.subscriptionTrialDays) {
				setSubscriptionStartDate(
					moment().add(parseInt(response.subscriptionTrialDays), 'days').format('MM/DD/YYYY')
				);
			}

			if (response.hasDMD) {
				if (response.dmdExpirationDate) {
					// let tempDmdExpirationDate = moment().add(
					// 	parseInt(response.dmdExpirationDays),
					// 	'days'
					// );

					let tempDmdExpirationDate = moment(response.dmdExpirationDate);
					setDmdExpirationDate(tempDmdExpirationDate);

					if (tempDmdExpirationDate && tempDmdExpirationDate.isBefore(moment(), 'day')) {
						setOfferDMDExpired(true);
					}
				}

				setOfferFullPrice(parseFloat(response.price) + parseFloat(response.discount));
			}

			if (response.offerItems && response.offerItems.length > 1) {
				let tempPaymentPlanStr = '';

				for (let i = 0; i < response.offerItems.length; i++) {
					let offerItem = response.offerItems[i];
					let offerItemDueDate = moment().add(parseInt(offerItem.paymentDaysDelay), 'days');

					tempPaymentPlanStr += `${formatCurrency(offerItem.price, true)} on ${offerItemDueDate.format(
						'MM/DD/YYYY'
					)}, `;
				}

				tempPaymentPlanStr = tempPaymentPlanStr.substring(0, tempPaymentPlanStr.length - 2);

				setOfferPaymentPlan(tempPaymentPlanStr);
			}
		}

		setIsLoading(false);
	};

	const handleSubmit = async () => {
		let isValid = document.querySelector('form').reportValidity();

		if (!isValid) {
			return;
		}

		setIsSubmitting(true);

		let data = { ...offerData };
		data.offerId = id;

		if (offerData.user) {
			data.firstName = offerData.user.firstName;
			data.lastName = offerData.user.lastName;
			data.email = offerData.user.email;
			data.phone = offerData.user.phoneNumber;
		} else {
			data.firstName = userFirstName;
			data.lastName = userLastName;
			data.email = userEmail;
			data.phone = userPhone;
		}

		//card data
		data.nameOnCard = ''; //cardNameOnCard;
		data.cardNumbers = cardNumbers;
		data.expMonth = cardExpMonth;
		data.expYear = cardExpYear;
		data.cvc = cardCVC;
		data.billingZip = cardBillingZip;
		data.type = getOfferType();

		//other params
		data.url = window.location.href;

		//trim all string values
		for (let [ key, value ] of Object.entries(data)) {
			//console.log(`${key}: ${value}`);
			if (typeof value === 'string') {
				data[key] = value.trim();
			}
		}

		let response = await ProcessOrder(data);

		if (response.success) {
			deleteOfferType();

			//let offerId = response.offerId;
			let orderIds = response.orderIds;

			if (orderIds && orderIds.length === 1) {
				setTimeout(function() {
					navigate(`/orders/${orderIds[0]}/success`);
				}, 1000);
			} else {
				navigate('/campaigns');
			}
		} else {
			setIsSubmitting(false);
			toast.error('Process failed. ' + response.message);
		}
	};

	return (
		<Fragment>
			<div className="container-fluid offer-details-container pb-0">
				<div className="container">
					<div className="row">
						<div className="col-12 text-sm-start text-center">{<Logo size="medium" />}</div>
						{/* <div className="col-6" /> */}
					</div>
				</div>
			</div>

			<div className="container-fluid offer-details-container">
				<div className="container">
					<div className="offer-details-container-inner">
						{isLoading ? (
							<div className="text-center">
								<Spinner />
							</div>
						) : (
							<Fragment>
								{!offerIsActive && (
									<div className="text-center">
										<h4 className="offer-title">This offer has been completed</h4>
									</div>
								)}

								{offerIsActive && (
									<form>
										{offerData && (
											<Fragment>
												<div className="row mb-2">
													<div className="col-12 text-center">
														<h2 className="offer-title">Payment Details</h2>
													</div>
												</div>

												<div className="row mb-0 mb-sm-3">
													<div className="col-12">
														<hr />
													</div>
												</div>

												{offerData.hasDMD && (
													<div className="row mb-3 offer-discount-wrapper">
														<div className="col-12 text-center">
															{offerData.discount && (
																<Fragment>
																	<div className="offer-discount-amount">{`Save ${formatCurrency(
																		offerData.discount,
																		true
																	)}!`}</div>

																	{offerDMDExpired ? (
																		<div>This Offer Expired</div>
																	) : (
																		<Fragment>
																			<div>This Offer Expires On:</div>
																			<div>
																				<strong>{`${dmdExpirationDate.format(
																					'MMMM DD, yyyy'
																				)} at Midnight`}</strong>
																			</div>
																		</Fragment>
																	)}

																	<div className="offer-timer">
																		<CountDownTimer
																			targetDate={dmdExpirationDate}
																		/>
																	</div>
																</Fragment>
															)}
														</div>
													</div>
												)}

												<div className="row mb-2">
													<div
														className="col-12"
														dangerouslySetInnerHTML={{ __html: offerData.description }}
													/>
												</div>

												<div className="row mb-3">
													<div className="col-12">
														<div className="offer-payment-details-wrapper">
															<div className="offer-name">{`${offerData.product
																? offerData.product.name
																: offerData.name}${offerData.numberOfLocations &&
															offerData.numberOfLocations !== 1
																? ` (${offerData.numberOfLocations} Locations)`
																: ''}`}</div>
															<div className="offer-price">
																<span>Just</span>

																{offerDMDExpired ? (
																	<span className="ms-2">
																		{formatCurrency(offerFullPrice, true)}
																	</span>
																) : (
																	<Fragment>
																		{offerFullPrice && (
																			<span className="offer-full-price ms-2">
																				{formatCurrency(offerFullPrice, true)}
																			</span>
																		)}

																		{offerData.offerItems &&
																		offerData.offerItems.length > 1 ? (
																			<span className="ms-2">
																				{formatCurrency(
																					offerData.offerItems[0].price,
																					true
																				)}
																			</span>
																		) : (
																			<span className="ms-2">
																				{formatCurrency(offerData.price, true)}
																			</span>
																		)}
																	</Fragment>
																)}

																<span className="ms-2">Today</span>
															</div>
															{offerData.isSubscription && (
																<div className="offer-subscription mt-1">{`(${formatCurrency(
																	(offerData.numberOfLocations || 1) *
																		offerData.subscriptionAmount,
																	true
																)} Per Month Starting ${subscriptionStartDate})`}</div>
															)}
														</div>
													</div>
												</div>

												{offerPaymentPlan && (
													<div className="row mb-2">
														<div className="col-12">
															<div className="offer-payment-details-wrapper">
																<p>
																	{`You have been approved for a payment plan that will be billed on
															the following dates: ${offerPaymentPlan}`}
																</p>
															</div>
														</div>
													</div>
												)}

												{/* {offerDMDExpired && (
												<div className="row mb-2">
													<div className="col-12 text-center">
														<div className="offer-expired-note">This Offer Has Expired</div>
													</div>
												</div>
											)} */}

												{/* {!offerDMDExpired && (
												<Fragment> */}
												{!offerData.user && (
													<div className="row mb-2 user-details-wrapper">
														<div className="col-12 col-sm-6 mb-3">
															<label htmlFor="userFirstName" className="form-label">
																First Name:
															</label>
															<input
																type="text"
																className="form-control"
																name="userFirstName"
																value={userFirstName || ''}
																onChange={(e) => setUserFirstName(e.target.value)}
																required
																disabled={isSubmitting}
															/>
														</div>

														<div className="col-12 col-sm-6 mb-3">
															<label htmlFor="userLastName" className="form-label">
																Last Name:
															</label>
															<input
																type="text"
																className="form-control"
																name="userLastName"
																value={userLastName || ''}
																onChange={(e) => setUserLastName(e.target.value)}
																required
																disabled={isSubmitting}
															/>
														</div>

														<div className="col-12 col-sm-6 mb-3">
															<label htmlFor="userEmail" className="form-label">
																Email:
															</label>
															<input
																type="text"
																className="form-control"
																name="userEmail"
																value={userEmail || ''}
																onChange={(e) => setUserEmail(e.target.value)}
																required
																disabled={isSubmitting}
															/>
														</div>

														<div className="col-12 col-sm-6 mb-3">
															<label htmlFor="userPhone" className="form-label">
																Phone:
															</label>
															<input
																type="text"
																className="form-control"
																name="userPhone"
																value={userPhone || ''}
																onChange={(e) => setUserPhone(e.target.value)}
																required
																disabled={isSubmitting}
															/>
														</div>
													</div>
												)}

												<div className="row mb-2 offer-card-wrapper">
													<div className="col-12 mb-3">
														<label htmlFor="cardNumbers" className="form-label">
															Card Numbers:
														</label>
														<input
															type="text"
															className="form-control"
															name="cardNumbers"
															value={cardNumbers || ''}
															onChange={(e) => setCardNumbers(e.target.value)}
															required
															disabled={isSubmitting}
														/>
													</div>

													<div className="col-6 mb-3">
														<label htmlFor="cardExpMonth" className="form-label">
															Exp Month:
														</label>
														<select
															name="cardExpMonth"
															className="form-select"
															value={cardExpMonth || ''}
															onChange={(e) => setCardExpMonth(e.target.value)}
															required
															disabled={isSubmitting}
														>
															<option value="" disabled="" />
															{expMonths.map((month) => (
																<option key={month} value={month}>
																	{month}
																</option>
															))}
														</select>
													</div>

													<div className="col-6 mb-3">
														<label htmlFor="cardExpYear" className="form-label">
															Exp Year:
														</label>
														<select
															name="cardExpYear"
															className="form-select"
															value={cardExpYear || ''}
															onChange={(e) => setCardExpYear(e.target.value)}
															required
															disabled={isSubmitting}
														>
															<option value="" disabled="" />
															{expYears.map((year) => (
																<option value={year} key={year}>
																	{year}
																</option>
															))}
														</select>
													</div>

													<div className="col-6 mb-3">
														<label htmlFor="cardCVC" className="form-label">
															CVC:
														</label>
														<input
															type="text"
															className="form-control"
															name="cardCVC"
															value={cardCVC || ''}
															onChange={(e) => setCardCVC(e.target.value)}
															required
															disabled={isSubmitting}
														/>
													</div>

													<div className="col-6 mb-3">
														<label htmlFor="cardBillingZip" className="form-label">
															Billing ZIP:
														</label>
														<input
															type="text"
															className="form-control"
															name="cardBillingZip"
															value={cardBillingZip || ''}
															onChange={(e) => setCardBillingZip(e.target.value)}
															required
															disabled={isSubmitting}
														/>
													</div>

													<label className="form-label col-12 mt-2">
														NOTE: We do not accept prepaid or temporary cards.
													</label>
												</div>

												<div className="row mb-2 pt-3">
													<div className="col-12">
														<button
															type="button"
															onClick={handleSubmit}
															className="btn submit-button"
															disabled={isSubmitting}
														>
															{isSubmitting ? (
																<Fragment>
																	<span className="spinner-border m-0 me-2" />
																	<span>Submitting...</span>
																</Fragment>
															) : (
																<span>Submit Order</span>
															)}
														</button>
													</div>
												</div>
												{/* </Fragment>
											)} */}

												<div className="row mb-2">
													<div className="col-12">
														<div className="offer-note text-center">
															By clicking the submit button above you agree to the{' '}
															<a
																href="https://mapboost.com/terms-of-use/"
																target="_blank"
																rel="noopener"
															>
																terms
															</a>,&nbsp;<a
																href="https://mapboost.com/privacy-policy/"
																target="_blank"
																rel="noopener"
															>
																privacy policy
															</a>&nbsp;and RealWebsite&nbsp;<a
																href="https://mapboost.com/terms"
																target="_blank"
																rel="noopener"
															>
																subscription (hosting) agreement
															</a>
														</div>
														<div className="mt-3 text-center">
															<img src="/img/cards.jpg" width={250} />
														</div>
														<div className="offer-note text-center mt-2">
															Your order is 100% safe and secure
														</div>
													</div>
												</div>
											</Fragment>
										)}
									</form>
								)}
							</Fragment>
						)}
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default OfferOrderPage;
