import { Fragment, useEffect, useState } from 'react';
import Spinner from '../../../components/spinner/Spinner';
import './CreateRequirements.css';
import { CreateNewRequirements, GetCampaignOrderDetails } from '../../../Services';
import Logo from '../../../components/logo/Logo';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const CreateRequirements = () => {
	const [ isLoading, setIsLoading ] = useState(false);
	const [ isSubmitting, setIsSubmitting ] = useState(false);
	const [ orderData, setOrderData ] = useState({});
	const [ requirementsData, setRequirementsData ] = useState({});
	const [ userFirstName, setUserFirstName ] = useState();
	const [ userLastName, setUserLastName ] = useState();
	const [ userEmail, setUserEmail ] = useState();
	const [ userPhone, setUserPhone ] = useState();
	const { id } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		document.title = 'Map Boost Setup Requirements';

		getOrderDetails();
	}, []);

	const getOrderDetails = async () => {
		setIsLoading(true);

		let response = await GetCampaignOrderDetails(id);

		if (response.success && response.data) {
			setOrderData(response.data);

			setUserFirstName(response.data.firstName);
			setUserLastName(response.data.lastName);
			setUserEmail(response.data.email);
			setUserPhone(response.data.phoneNumber);
		}

		setIsLoading(false);
	};

	const handleChange = async (e) => {
		setRequirementsData({
			...requirementsData,
			[e.target.name]: e.target.value
		});
	};

	const handleSubmit = async () => {
		let isValid = document.querySelector('form').reportValidity();

		if (!isValid) {
			return;
		}

		setIsSubmitting(true);

		let data = { ...requirementsData };
		data.userId = orderData.offer.userId;
		//data.offerId = orderData.id;
		data.orderId = orderData.id;
		data.email = userEmail;

		let response = await CreateNewRequirements(data);

		if (response.success) {
			setTimeout(function() {
				navigate('/requirements/success');
			}, 1000);
		} else {
			setIsSubmitting(false);
			toast.error('Process failed. ' + response.message);
		}
	};

	return (
		<Fragment>
			<div className="container-fluid requirements-details-container pb-0">
				<div className="container">
					<div className="row">
						<div className="col-12 text-sm-start text-center">{<Logo size="medium" />}</div>
					</div>
				</div>
			</div>

			<div className="container-fluid requirements-details-container">
				<div className="container">
					{isLoading ? (
						<div className="text-center">
							<Spinner />
						</div>
					) : (
						<form>
							<div className="requirements-details-container-inner">
								{requirementsData && (
									<Fragment>
										<div className="row mb-2">
											<div className="col-12 text-center">
												<h2 className="requirements-title">Map Boost Setup Requirements</h2>
											</div>
										</div>

										<div className="row mb-0 mb-sm-3">
											<div className="col-12">
												<hr />
											</div>
										</div>

										<div className="row mb-3">
											<div className="col-12">
												<p>
													Please enter your details below to be used to setup the Map Boost
													directory. The business contact information below should match what
													is displayed on your Google Business Profile.
												</p>
											</div>
										</div>

										<div className="row mb-3">
											{/* <div className="col-12 mb-4">
												<label htmlFor="userFirstName" className="form-label">
													First Name:
												</label>
												<input
													type="text"
													className="form-control"
													name="userFirstName"
													value={userFirstName || ''}
													onChange={(e) => setUserFirstName(e.target.value)}
													required
													disabled={isSubmitting}
												/>
											</div>

											<div className="col-12 mb-4">
												<label htmlFor="userLastName" className="form-label">
													Last Name:
												</label>
												<input
													type="text"
													className="form-control"
													name="userLastName"
													value={userLastName || ''}
													onChange={(e) => setUserLastName(e.target.value)}
													required
													disabled={isSubmitting}
												/>
											</div>

											<div className="col-12 mb-4">
												<label htmlFor="userEmail" className="form-label">
													Email:
												</label>
												<input
													type="text"
													className="form-control"
													name="userEmail"
													value={userEmail || ''}
													onChange={(e) => setUserEmail(e.target.value)}
													required
													disabled={isSubmitting}
												/>
												<div className="field-note">
													This is the email that we will send email notifications and campaign
													reports
												</div>
											</div>

											<div className="col-12 mb-4">
												<label htmlFor="userPhone" className="form-label">
													Phone:
												</label>
												<input
													type="text"
													className="form-control"
													name="userPhone"
													value={userPhone || ''}
													onChange={(e) => setUserPhone(e.target.value)}
													required
													disabled={isSubmitting}
												/>
											</div> */}

											<div className="col-12 mb-4">
												<label htmlFor="userMobilePhone" className="form-label">
													Mobile Number for SMS (text) Campaign Update Notifications:
												</label>
												<input
													type="text"
													className="form-control"
													name="mobilePhone"
													value={requirementsData.mobilePhone || ''}
													onChange={handleChange}
													required
													disabled={isSubmitting}
												/>
												<div className="field-note">
													By submitting this form you agree to be contacted by SMS/Text by the
													Map Boost sales or support team regarding fulfillment of the Map
													Boost service or support requests.
												</div>
											</div>
										</div>

										<div className="row mb-2">
											<div className="col-12">
												<h3 className="requirements-subtitle">
													Details For The Business Being Map Boosted:
												</h3>
											</div>
										</div>

										<div className="row mb-0 mb-sm-3">
											<div className="col-12">
												<hr />
											</div>
										</div>

										<div className="row mb-3">
											<div className="col-12 mb-4">
												<label htmlFor="company" className="form-label">
													Company Name:
												</label>
												<input
													type="text"
													className="form-control"
													name="company"
													value={requirementsData.company || ''}
													onChange={handleChange}
													required
													disabled={isSubmitting}
												/>
											</div>

											<div className="col-12 mb-4">
												<label htmlFor="website" className="form-label">
													Website URL:
												</label>
												<input
													type="text"
													className="form-control"
													name="website"
													value={requirementsData.website || ''}
													onChange={handleChange}
													required
													disabled={isSubmitting}
												/>
											</div>

											<div className="col-12 mb-4">
												<label htmlFor="googleBusinessUrl" className="form-label">
													Google Map URL (Optional):
												</label>
												<input
													type="text"
													className="form-control"
													name="googleBusinessUrl"
													value={requirementsData.googleBusinessUrl || ''}
													onChange={handleChange}
													disabled={isSubmitting}
												/>
												<div className="field-note">
													If you do not know how to find this we can look it up for you.
												</div>
											</div>

											<div className="col-12 mb-4">
												<label htmlFor="address" className="form-label">
													Business Address:
												</label>
												<input
													type="text"
													className="form-control"
													name="address"
													value={requirementsData.address || ''}
													onChange={handleChange}
													required
													disabled={isSubmitting}
												/>
												<div className="field-note">
													<strong>IMPORTANT:</strong> Please enter your business location
													address. If you are a service area business, please enter the
													address where you typically start from to travel to your customers.
													We need the address that Google believes is your business address,
													even if you are not a location business.
												</div>
											</div>

											<div className="col-12 mb-4">
												<label htmlFor="businessPhone" className="form-label">
													Business Phone Number:
												</label>
												<input
													type="text"
													className="form-control"
													name="businessPhone"
													value={requirementsData.businessPhone || ''}
													onChange={handleChange}
													required
													disabled={isSubmitting}
												/>
											</div>

											<div className="col-12 mb-4">
												<label htmlFor="industry" className="form-label">
													What is your business industry? (Plumber, Real Estate Agent, Lawyer)
												</label>
												<input
													type="text"
													className="form-control"
													name="industry"
													value={requirementsData.industry || ''}
													onChange={handleChange}
													required
													disabled={isSubmitting}
												/>
												<div className="field-note">
													This is the primary service/industry of your business. We will
													research the keyword(s) that best matches your industry and gets the
													most search volume to boost your profile rankings for that keyword.
												</div>
											</div>

											<div className="col-12 mb-4">
												<label htmlFor="services" className="form-label">
													What are all the services you offer?
												</label>
												<textarea
													className="form-control"
													name="services"
													value={requirementsData.services || ''}
													onChange={handleChange}
													rows={5}
													required
													disabled={isSubmitting}
												/>
												<div className="field-note">
													Enter are all the main services you offer. We will use this to
													research the best keywords with the most search volume and add these
													as keywords in the directory site content to help with rankings.
												</div>
											</div>

											<div className="col-12 mb-4">
												<label htmlFor="specialInstructions" className="form-label">
													Special Instructions, Location or Content Exclusions & Additional
													Comments:
												</label>
												<textarea
													className="form-control"
													name="specialInstructions"
													value={requirementsData.specialInstructions || ''}
													onChange={handleChange}
													rows={5}
													disabled={isSubmitting}
												/>
												<div className="field-note">
													Tell us if there are any location restrictions (state licensing
													limitations) or specific content that cannot be used on your pages
													so we can be sure to exclude.
												</div>
											</div>
										</div>

										<div className="row mb-2">
											<div className="col-12">
												<button
													type="button"
													onClick={handleSubmit}
													className="btn submit-button"
												>
													{isSubmitting ? (
														<Fragment>
															<span className="spinner-border m-0 me-2" />
															<span>Submitting...</span>
														</Fragment>
													) : (
														<span>Submit</span>
													)}
												</button>
											</div>
										</div>
									</Fragment>
								)}
							</div>
						</form>
					)}
				</div>
			</div>
		</Fragment>
	);
};

export default CreateRequirements;
