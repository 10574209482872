import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import CenterContainer from '../../../components/centerContainer/CenterContainer';
import Spinner from '../../../components/spinner/Spinner';
import { StoreSalesBusiness } from '../../../utils/StorageUtil';
import './BusinessProfileConnect.css';
import { toast } from 'react-toastify';
import {
	FindGoogleProfileInformation,
	GetGooglePlaceDetails,
	GetUsers,
	SerpFindGoogleProfileByPlaceId
} from '../../../Services';
import { Fragment } from 'react';
import { useStateValue } from '../../../StateProvider';
import Select from 'react-select';
import ModalDialog from '../../../components/modalDialog/ModalDialog';
import ModalDialogContent from '../../../components/modalDialog/modalDialogContent/ModalDialogContent';
import ModalDialogButtons from '../../../components/modalDialog/modalDialogButtons/ModalDialogButtons';
import { parseAddressFromGoogleProfile } from '../../../utils/Common';

const BusinessProfileConnect = () => {
	const [ isLoading, setIsLoading ] = useState(false);
	const [ selectedBusiness, setSelectedBusiness ] = useState();
	const [ { user }, dispatch ] = useStateValue();
	const [ shareMapUrl, setShareMapUrl ] = useState();
	const navigate = useNavigate();
	const [ activeUsers, setActiveUsers ] = useState([]);
	const [ selectedUser, setSelectedUser ] = useState();
	const [ showFindShareLinkVideoDialog, setShowFindShareLinkVideoDialog ] = useState(false);
	const daysInWeek = [ 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday' ];

	useEffect(() => {
		if (user && user.isSalesUser) {
			getActiveUsers();
		}
	}, []);

	useEffect(
		() => {
			if (selectedBusiness) {
				// if (campaignId) {
				// 	selectedBusiness.campaignId = campaignId;
				// }

				StoreSalesBusiness(selectedBusiness);

				if (selectedBusiness.streetAddress1) {
					navigate('/grid-report-tool');
				} else {
					navigate('/profile-address');
				}
			}
		},
		[ selectedBusiness ]
	);

	useEffect(
		() => {
			if (activeUsers) {
				setSelectedUser(activeUsers[0]);
			}
		},
		[ activeUsers ]
	);

	const getActiveUsers = async () => {
		let response = await GetUsers();

		if (response && response.length) {
			let tempUsers = response.filter((x) => x.status === 'Active');

			let tempUsersForSelect = [
				{
					value : '0',
					label : 'Create New User'
				}
			];

			//sort users alphabetically
			tempUsers = tempUsers.sort((a, b) => a.firstName.localeCompare(b.firstName));

			for (let i = 0; i < tempUsers.length; i++) {
				tempUsersForSelect.push({
					value : response[i].id,
					label : `${response[i].firstName} ${response[i].lastName} (${response[i].email})`
				});
			}

			setActiveUsers(tempUsersForSelect);
		}
	};

	const handleMapUrlLookup = async (e) => {
		e.preventDefault();

		if (!shareMapUrl || shareMapUrl.trim === '' || !shareMapUrl.startsWith('https://maps.app.goo.gl')) {
			toast.error('Invalid Google Maps share URL');
			setShareMapUrl();
			return;
		}

		setIsLoading(true);

		var response = await FindGoogleProfileInformation(shareMapUrl);

		if (response.success) {
			let placeId = response.placeId;
			let cid = response.cid;

			let tempLocation = {};
			tempLocation.googleBusinessUrl = shareMapUrl;
			tempLocation.place_id = placeId;
			tempLocation.cid = cid;

			let placeDetails = await GetGooglePlaceDetails(placeId);

			if (placeDetails && placeDetails.address_components) {
				tempLocation.isServiceArea = false;

				let addressData = parseAddressFromGoogleProfile(placeDetails.address_components);

				tempLocation = {
					...tempLocation,
					...addressData,
					name                   : placeDetails.name,
					website                : placeDetails.website,
					url                    : placeDetails.url,
					formatted_phone_number : placeDetails.formatted_phone_number,
					lat                    : placeDetails.geometry.location.lat,
					lng                    : placeDetails.geometry.location.lng
				};
			} else {
				tempLocation.isServiceArea = true;

				var response = await SerpFindGoogleProfileByPlaceId(placeId);

				if (response.success && response.data) {
					let responseJson = JSON.parse(response.data);

					if (responseJson && responseJson.place_results) {
						tempLocation.name = responseJson.place_results.title || '';
						tempLocation.website = responseJson.place_results.website || '';
						tempLocation.formatted_phone_number = responseJson.place_results.phone || '';

						if (!tempLocation.cid || tempLocation.cid === '') {
							tempLocation.cid = responseJson.place_results.data_cid || '';
						}

						//working hours
						let hoursData = responseJson.place_results.hours;
						if (hoursData && hoursData.length > 0) {
							hoursData.forEach((hoursDataItem) => {
								daysInWeek.forEach((day) => {
									if (hoursDataItem[day.toLowerCase()]) {
										if (hoursDataItem[day.toLowerCase()].indexOf('Open 24 hours') > -1)
											tempLocation[`hrs${day}`] = '00:00-23:59';
										else if (hoursDataItem[day.toLowerCase()].indexOf('Closed') > -1)
											tempLocation[`hrs${day}`] = null;
										else {
											let hoursArgs = hoursDataItem[day.toLowerCase()].split('–');

											if (hoursArgs && hoursArgs.length === 2) {
												tempLocation[`hrs${day}`] =
													convertAmPmTo24Hours(hoursArgs[0].trim(), true) +
													'-' +
													convertAmPmTo24Hours(hoursArgs[1].trim(), false);
											}
										}
									}
								});
							});
						}
					}
				}
			}

			setSelectedBusiness(tempLocation);
		} else {
			if (response.errorMessage === 'Place ID not found') {
				toast.error(response.errorMessage + '. Please, try again');
			} else {
				toast.error(response.errorMessage);
				setShareMapUrl();
			}
		}

		setIsLoading(false);
	};

	const convertAmPmTo24Hours = (hoursValue, isOpen) => {
		let hours24;

		if (hoursValue.trim().indexOf('am') > -1 || hoursValue.trim().indexOf('AM') > -1) {
			hours24 = hoursValue.replace('am', '').replace('AM', '').trim();

			//add leading zero
			hours24 = parseInt(hours24);
			if (hours24 < 10) {
				hours24 = '0' + hours24.toString();
			}
		} else if (hoursValue.trim().indexOf('pm') > -1 || hoursValue.trim().indexOf('PM') > -1) {
			hours24 = hoursValue.replace('pm', '').replace('PM', '').trim();
			hours24 = parseInt(hours24) + 12;
		} else if (hoursValue.indexOf('Open 24 hours') > -1) {
			if (isOpen) {
				hours24 = '00:00';
			} else {
				hours24 = '23:59';
			}
		} else {
			hours24 = hoursValue;
		}

		if (hours24.toString().indexOf(':') === -1) {
			hours24 = hours24 + ':00';
		}

		return hours24;
	};

	return (
		<Fragment>
			<div className="main-content-container business-profile-container">
				<div className="main-content-inner-container">
					<div className="business-profile-connect-container">
						{isLoading ? (
							<Spinner />
						) : (
							<Fragment>
								<h2 className="mb-3">Connect Your Business Profile</h2>
								<div className="text-start mb-sm-5 mb-4">
									Copy and paste in your business profile share link. The link is available from your
									profile displayed on Google Maps.
								</div>
								{/* {user &&
								user.isSalesUser && (
									<div className="mb-4 text-start">
										{activeUsers &&
										selectedUser && (
											<Select
												options={activeUsers}
												value={selectedUser}
												onChange={(e) => {
													setSelectedUser(e);
												}}
												required
												ref={selectedUserRef}
											/>
										)}
									</div>
								)} */}

								<div className="mb-3">
									<input
										type="text"
										className="form-control"
										placeholder="Enter Share Map Url"
										value={shareMapUrl || ''}
										onChange={(e) => setShareMapUrl(e.target.value)}
									/>
								</div>
								<div className="mb-sm-5 mb-4">
									<button className="btn btn-primary" onClick={handleMapUrlLookup}>
										Continue
									</button>
								</div>
								<div>
									<button
										className="btn btn-link p-0 text-decoration-underline"
										onClick={() => setShowFindShareLinkVideoDialog((prev) => !prev)}
									>
										Show me how to find the profile share link?
									</button>
								</div>
							</Fragment>
						)}
					</div>

					{showFindShareLinkVideoDialog && (
						<ModalDialog>
							<ModalDialogContent>
								<div style={{ position: 'relative', paddingBottom: '56.25%', height: 0 }}>
									<iframe
										src="https://www.loom.com/embed/0ef16571206142e9b35864ad87f7fbd3?sid=bac9cd1e-e702-45b0-a82d-239046cfd5c3"
										allowFullScreen
										style={{
											position : 'absolute',
											top      : 0,
											left     : 0,
											width    : '100%',
											height   : '100%'
										}}
									/>
								</div>
							</ModalDialogContent>
							<ModalDialogButtons>
								<button
									className="btn btn-outline-primary"
									onClick={() => setShowFindShareLinkVideoDialog((prev) => !prev)}
								>
									Close
								</button>
							</ModalDialogButtons>
						</ModalDialog>
					)}
				</div>
			</div>
		</Fragment>
	);
};

export default BusinessProfileConnect;
