import { Fragment, useEffect, useRef, useState } from 'react';
import Spinner from '../../../components/spinner/Spinner';
import './CompanyGridReportsList.css';
import { GetSalesCompanyTasks } from '../../../Services';
import { Link, useParams } from 'react-router-dom';
import PageHeadline from '../../../components/pageHeadline/PageHeadline';
import Moment from 'react-moment';
import moment from 'moment';
import { useStateValue } from '../../../StateProvider';
import TableFooter from '../../../components/table/footer/TableFooter';

const CompanyGridReportsList = () => {
	const [ isLoading, setIsLoading ] = useState(false);
	const [ allReports, setAllReports ] = useState();
	const [ reportsView, setReportsView ] = useState();
	const [ { user }, dispatch ] = useStateValue();
	const searchListInputRef = useRef();
	const { id } = useParams();

	useEffect(() => {
		getCompanyReportsList(true);
	}, []);

	const filterList = () => {
		if (allReports && allReports.length > 0) {
			setCompanyReportsView(allReports);
		}
	};

	const onSearchChange = (e) => {
		if (searchListInputRef.current) {
			if (searchListInputRef.current.value === '') {
				searchListInputRef.current.classList.remove('has-value');
			} else {
				searchListInputRef.current.classList.add('has-value');
			}
		}

		filterList();
	};

	const getCompanyReportsList = async (fullReload = false) => {
		if (fullReload) {
			setIsLoading(true);
		}

		let response = await GetSalesCompanyTasks(id);

		if (response && response.length > 0) {
			let tempAllReports = response;

			//sort by order index
			tempAllReports = tempAllReports.sort(function(a, b) {
				return a.orderIndex - b.orderIndex;
			});

			setAllReports(tempAllReports);

			setCompanyReportsView(tempAllReports);
		} else {
			setReportsView();
		}

		setIsLoading(false);
	};

	const setCompanyReportsView = (reports) => {
		let tempReportsView = reports;

		if (searchListInputRef.current && searchListInputRef.current.value) {
			tempReportsView = reports.filter((x) =>
				x.salesCompanyName.toLowerCase().includes(searchListInputRef.current.value.toLowerCase())
			);
		}

		tempReportsView = tempReportsView.sort(function(a, b) {
			return new Date(b.dateCreated) - new Date(a.dateCreated);
		});

		setReportsView(tempReportsView);
	};

	return (
		<Fragment>
			<div className="container-fluid p-0">
				<div className="row">
					<div className="col-12">
						<PageHeadline
							//headline="Reports"
							leftSideHtml={
								<Link type="button" className="btn btn-primary" to="/profile-connect">
									New Grid Report
								</Link>
							}
							buttonsHtml={
								<Fragment>
									{user && (
										<div className="d-flex justify-content-end">
											<div className="search-list-wrapper align-self-start">
												<input
													type="search"
													className="form-control"
													name="searchListTerm"
													onChange={onSearchChange}
													placeholder="Search List"
													ref={searchListInputRef}
													tabIndex="0"
												/>
												<button type="button" className="btn btn-link btn-search px-2">
													<i className="fa fa-search me-0" />
												</button>
											</div>
										</div>
									)}
								</Fragment>
							}
						/>
					</div>
				</div>

				<div className="main-content-container reports-list-container">
					<div className="main-content-inner-container">
						<table className="items-list company-reports-list table table-responsive">
							<thead>
								<tr>
									<th scope="col">Keyword</th>
									<th className="date text-start d-none d-sm-table-cell" scope="col">
										Radius
									</th>
									<th className="date d-none d-sm-table-cell" scope="col">
										Date
									</th>
									<th scope="col" />
								</tr>
							</thead>
							{isLoading ? (
								<tbody>
									<tr>
										<td className="p-3" colSpan={4}>
											<Spinner />
										</td>
									</tr>
								</tbody>
							) : (
								<tbody>
									{reportsView && reportsView.length > 0 ? (
										reportsView.map((report, index) => (
											<tr key={index}>
												<td>{report.keyword}</td>
												<td className="date d-none d-sm-table-cell">
													{report.gridRadius ? `${report.gridRadius} Miles` : ''}
												</td>
												<td className="date text-start d-none d-sm-table-cell">
													<Moment format="MM/DD/YYYY">{report.dateCreated}</Moment>
												</td>
												<td className="task-action text-end">
													<Link
														to={`/reports/${report.id}`}
														className="btn btn-link py-0"
														title="View Report"
													>
														View
													</Link>
												</td>
											</tr>
										))
									) : (
										<tr>
											<td className="p-3" colSpan={5}>
												<i>No reports found</i>
											</td>
										</tr>
									)}
								</tbody>
							)}
							{!isLoading && (
								<TableFooter
									list={reportsView}
									colspan={4}
									showRefreshButton={true}
									onListRefresh={() => getCompanyReportsList(true)}
								/>
							)}
						</table>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default CompanyGridReportsList;
